import { App } from '@capacitor/app'
import { createRouter, createWebHistory } from 'vue-router'
import { beforeEach } from './guard'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { setVerticalScrollPosition } from '~common/utils/scroll'

const routes = setupLayouts(generatedRoutes)

const router = createRouter({
  history: createWebHistory(import.meta.env.PUBLIC_BASE_URL),
  linkActiveClass: 'is-link-active',
  linkExactActiveClass: 'is-exact-active',
  scrollBehavior(to, from) {
    if (from.path === to.path) {
      return
    }

    if (to.params.id) {
      return
    }

    setVerticalScrollPosition(null, 0)

    // Ниже работает только для скролиремого контейнера window. Если свой контейнер, то ниже запись бесмыслена
    // return { top: 0 }
  },
  routes
})

router.beforeEach(beforeEach)

App.addListener('backButton', data => {
  if (data.canGoBack) {
    router.back()

    return
  }

  App.minimizeApp()
}).then()

export default router
